<template>
    <div class="page">
        <app-header :navIndex="1"></app-header>
        <banner :data="banner"></banner>
        <section class="top-box section-container mt-md-12">
            <div class="top-border hidden-md-and-up white"></div>
            <div class="desc pl-5 pr-5 pt-7 pl-md-0 pr-md-0 pt-md-12 pb-5" v-html="banner.desc"></div>
            <div v-for="(item,index) in list" :key="index" :class="index==list.length-1?'item-box':''">
                <div class="d-flex flex-wrap flex-md-row justify-md-space-between">
                    <div class="img-box col-12 col-md-3" :class="index%2==0?'order-md-first':'order-md-1'">
                        <!--<div class="num ml-6">{{'0' + (index + 1)}}</div>-->
                        <v-img class="img" :src="$utils.formatImgUrl(item.imgUrl)"></v-img>
                    </div>
                    <div class="desc-box col-12 col-md-8 order-md-0">
                        <sub-title :title="item.title"></sub-title>
                        <div class="desc mt-8 ml-md-5" v-html="item.desc"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import Banner from "../../components/base/banner";
    import SubTitle from "../../components/base/subtitle";
    import AppHeader from "../../components/core/header/index";
    export default {
        components: {
            AppHeader,
            SubTitle,
            Banner
        },
        data(){
            return {
                banner: {
                    imgUrl: 'company/history/bg.jpg',
//                    img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/social/bg.jpg'),
                    title:  this.$i18n.t('social.title'),
                    enTitle: '',
                    desc: this.$i18n.t('social.desc')
                },
                list: this.$i18n.t('social.list')
            }
        },
        created(){
            this.initData()
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "企业社会责任"
            document.getElementById("bodyId").appendChild(div);
        },
        methods: {
            initData(){
                this.$api.web.getSocialOrEmployee({type: 1}).then(res => {
                    if (res.rows.length > 0) {
                        this.list = res.rows
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .item-box {
        padding-bottom: 100px;
    }

    .img-box {
        .num {
            font-size: 150px;
            color: $primary-color;
            font-family: DINAlternate-Bold, DINAlternate;
        }
        .img {
            margin-top: 120px;
            /*margin-top: -120px;*/
        }
    }

    .desc-box {
        padding: 150px 0 50px 0;
    }

    @media (max-width: $screen-md) {
        .item-box {
            .img {
                margin-top: 60px;
            }
            padding-bottom: 50px;
        }
        .desc-box {
            padding: 20px;
        }
    }

    @media (max-width: $screen-sm) {
        .item-box {
            padding-bottom: 50px;
        }
        .img-box {
            .img {
                margin-top: 0;
            }
            .num {
                font-size: 140px;
            }
        }
    }

</style>
